import { Trans } from '@lingui/macro'
import Logo from 'components/src/core/Logo'
import Heading from 'components/src/typography/Heading'

import styles from './MemberCardTopBar.module.scss'

// eslint-disable-next-line react/display-name
export default () => (
    <>
        <Logo className={styles.logo} />
        <br />
        <Heading variant="h4" element="h4" className={styles.subheader}>
            <Trans id="user.membercard.logo.subheader">Outlet store</Trans>
        </Heading>
    </>
)
