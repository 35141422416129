import { ComponentConfig } from 'components/src/utils/ComponentOverridesContext'

import AccountLoginPageLayout from './components/account-login-page/AccountLoginPageLayout'
import AccountIcon from './components/AccountIcon'
import AppRouter from './components/AppRouter/AppRouter'
import BackIcon from './components/BackIcon'
import BackToTopIcon from './components/BackToTopIcon'
import BookmarkIcon, { BookmarkFilledIcon } from './components/BookmarkIcon'
import CartTotalsFooterPrice from './components/cart/CartTotals/CartTotalsFooterPrice'
import CartEmptyIcon from './components/CartEmptyIcon'
import CartIcon from './components/CartIcon'
import CheckIcon from './components/CheckIcon'
import CloseIcon from './components/CloseIcon'
import DropdownIcon from './components/DropdownIcon'
import GeoLocationIcon from './components/GeoLocationIcon'
import InfoIcon from './components/InfoIcon'
import MemberCardBody from './components/MemberCardBody'
import MemberCardTopBar from './components/MemberCardTopBar'
import NewsletterSubmit from './components/NewsletterSubmit'
import PlusIcon from './components/PlusIcon'
import SearchIcon from './components/SearchIcon'
import StoresIcon from './components/StoresIcon'
import SuccessPageBackgroundImages from './components/SuccessPage/SuccessPageBackgroundImages'
import UspCheckIcon from './components/UspCheckIcon'
import ThemeOverrides from './theme/ThemeOverrides'

const componentsConfig: ComponentConfig = {
    AccountIcon,
    InfoIcon,
    AccountLoginPageLayout,
    AppRouter,
    BackIcon,
    BackToTopIcon,
    BookmarkIcon,
    BookmarkFilledIcon,
    CartEmptyIcon,
    CartIcon,
    CheckIcon,
    CloseIcon,
    DropdownIcon,
    GeoLocationIcon,
    NewsletterSubmit,
    CartTotalsFooterPrice,
    PlusIcon,
    SearchIcon,
    StoresIcon,
    SuccessPageBackgroundImages,
    UspCheckIcon,
    ThemeOverrides,
    MemberCardTopBar,
    MemberCardBody,
}

export default componentsConfig
